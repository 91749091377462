import { Line, mixins } from 'vue-chartjs'
import datalabelsPlugin from 'chartjs-plugin-datalabels'

const { reactiveProp } = mixins
const LEGEND_MARGIN = 36

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
        this.addPlugin(datalabelsPlugin)
        this.addPlugin({
            beforeInit: (chart, options) => {
                chart.legend.afterFit = function() {
                    this.height = this.height + LEGEND_MARGIN
                }
            },
        })
        this.renderChart(this.chartData, this.options)
    },
}
